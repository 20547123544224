.root {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
}

.titleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 10px 15px;
}

.title {
    font-size: 16px;
    color: rgb(128, 229, 77);
    font-weight: normal;
    margin: 0;
    padding: 0;
}

.statisticsContainer {
    padding: 10px 15px;
}

.linkBar {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.linkContainerBorderRight {
    border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.linkContainer {
    flex: 1;
    padding: 10px 15px;
    text-align: center;
}

.link {
    color: rgb(0, 0, 0, 0.5) !important;
}

.linkIcon {
    margin-right: 10px;
}
