@font-face {
  font-family: 'LMSans';
  src: url('/assets/font/LeroyMerlinSans-Web-Regular.ttf');
}
@font-face {
  font-family: 'LMSans';
  src: url('/assets/font/LeroyMerlinSans-Web-Bold.ttf');
  font-weight: bold;
}
@font-face {
  font-family: 'LMSans';
  src: url('/assets/font/LeroyMerlinSans-Web-SemiBold.ttf');
  font-weight: 500;
}

body {
  font-family: 'LMSans', sans-serif !important;
}

.subcategory-card .ant-card-actions {
  background: white !important;
  display: flex;
}

.subcategory-card .ant-card-actions li {
  margin: 0px !important;
}

.form-collapsed .container {
  height: 50px;
  overflow: hidden;
}

.form-collapsed:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: inset 0 -20px 40px #FFFFFF;
  z-index: 1;
}

.ant-custom-table-filters.ant-table-filters {
  width: auto;
  margin: 0;
  padding: 0;
}

.ant-custom-table-filters.ant-table-filters.search > .input-container {
  width: 250px;
  padding: 16px;
}

.ant-custom-table-filters.ant-table-filters.search > .input-container > input {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 5px 10px;
}

.ant-custom-table-filters.ant-table-filters.search > .input-container > .ant-select {
  width: 100%;
}

.ant-custom-table-filters.ant-table-filters.search > .input-container > .operator-select {
  margin-bottom: 16px;
}

.ant-custom-table-filters.ant-table-filters > .buttons-container {
  padding: 16px;
  background-color: #fafafa;
  border-top: 1px solid #e8e8e8;
}

.ant-custom-table-filters .ant-custom-checkbox-wrapper {
  display: block;
  margin: 0;
  width: 100%;
}

.lm-table .ant-table-body {
  width: 100%;
  overflow-x: auto;
}

.ant-dropdown.shadow {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-table-row-ref-not-updated td {
  background-color: #fffbee !important;
}

.ant-input-error {
   border-color: #f5222d !important;
 }

.ant-input-error .ant-select-selection {
  border-color: #f5222d !important;
}

.ant-input-warning {
  border-color: rgb(250, 173, 20) !important;
}

.ant-input-warning .ant-select-selection {
  border-color: rgb(250, 173, 20) !important;
}

.ant-input-error.ant-select-focused .ant-select-selection, .ant-input-error.ant-select-open .ant-select-selection {
  box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2) !important;
}

.ant-table-row td {
  position: relative;
}

.ant-calendar-picker {
  width: 100%;
}

.ant-calendar-input-wrap  {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
.remove-spinner input::-webkit-outer-spin-button,
.remove-spinner input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.remove-spinner input[type=number] {
  -moz-appearance: textfield;
}

.remove-spinner .ant-input-number-handler-wrap {
  display: none;
}

/* AG GRID */

.ag-header-cell-text {
  white-space: normal !important;
  overflow: hidden !important;
  text-align: center !important;
  word-break: normal;
}

.ag-header-cell-label {
  justify-content: center !important;
  word-break: normal;
}

.ag-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ag-cell-inline-editing {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.ag-cell.lm-editable:not(.ag-cell-inline-editing) {
  padding-right: calc(17px + 40px) !important;
}

.ag-cell.lm-editable:not(.ag-cell-inline-editing):after {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  height: 15px;
  width: 36px;
  content: url('/assets/img/edit-icon.svg');
}

.ag-grid-custom-info-tooltip {
  position: absolute;
  width: 250px;
  height: 70px;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: none;
}

.ag-grid-custom-info-tooltip-inner {
  position: absolute;
  width: fit-content;
  height: fit-content;
  background-color: white;
  border: 1px solid rgba(250, 173, 20, 1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.ag-grid-custom-info-tooltip-inner.error {
  border-color: rgb(219, 94, 94);
}

.ag-grid-custom-info-tooltip-inner.info {
  border-color: #66CC33;
}

.ag-grid-custom-info-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.ag-grid-custom-info-tooltip p {
  margin: 5px;
}

.ag-grid-custom-info-tooltip p:first-of-type {
  font-weight: bold;
}

.ag-row.lm-error .ag-cell {
  background-color: rgba(219, 94, 94, 0.05);
}

.ag-cell.lm-error {
  border: 1px solid rgba(219, 94, 94, 1) !important;
}

.ag-row.lm-warning .ag-cell {
  background-color:rgba(250, 173, 20, 0.05);
}

.ag-cell.lm-warning {
  border: 1px solid rgba(250, 173, 20, 1) !important;
}

.ant-btn-icon-only i {
  vertical-align: -2px !important;
}

.ag-watermark {
  display: none !important;
}

.btn-forced-active {
  color: #49a621 !important;
  border-color: #49a621 !important;
  cursor: default;
  background-color: #fff !important;
  text-shadow: none !important;
  box-shadow: none !important;
}

.ag-cell.ag-cell-custom-input-editor .ag-react-container {
  height: 100%;
}

a {
  color: rgb(102, 204, 51) !important;
}

a:hover, a:active, a:focus {
  color: rgb(128, 229, 77) !important;
}


.header-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: none;
  border-radius: 5px;
  flex-wrap: wrap;
}

.header-grid > div {
  width: 25%;
  padding: 10px 15px;
  font-size: 14px;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.header-grid > div:nth-child(4n) {
  border-right: none;
}

.header-grid > div:nth-last-child(4) {
  border-bottom-left-radius: 5px;
}

.header-grid > div:nth-last-child {
  border-bottom-right-radius: 5px;
}

.header-grid > div:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.025);
}

.header-grid > div > span:nth-child(1) {
  opacity: 0.5;
}

.header-grid > div > span:nth-child(2) {
  font-weight: 400;
  margin-left: 15px;
}

.ag-react-container {
  min-height: 100%;
  display: flex;
  align-items: center;
}

.no-spinners, .no-spinners .ant-input-number-input {
  -moz-appearance: textfield;
}

/* For Chrome, Safari and Opera */
.no-spinners::-webkit-outer-spin-button,
.no-spinners::-webkit-inner-spin-button,
.no-spinners .ant-input-number-input::-webkit-outer-spin-button,
.no-spinners .ant-input-number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
